import Colors from '@app/themes/colors';
import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: '38px 1px 3px 79px',
  },
  boxContainer: {
    paddingRight: '80px',
    paddingLeft: '1px',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  visitPlanningText: {
    marginRight: 5,
  },
  visitCount: {
    width: '100%',
    textAlign: 'center',
  },
  visitCountContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '17%',
  },
  visitCountContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  visitCountBtns: {
    marginLeft: 10,
    flexDirection: 'row',
    paddingTop: '29px',
  },
  visitCountBtn: {
    paddingHorizontal: 10,
    borderRadius: 0,
    height: 32,
    marginRight: 10,
    width: 32,
  },
  visitDayContainer: {
    marginVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  visitDayContent: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
  },
  visitDropdownContainer: {
    width: '60%',
    flexDirection: 'row',
  },
  visitPatchName: {
    width: '40%',
  },
  dayVisitDropdown: {
    marginRight: 15,
    flex: 1,
  },
  searchContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'end',
    width: '70%',
    minHeight: 78,
  },
  visitCountBox: {
    marginLeft: '10px',
  },
  paperButton: {
    border: '3px solid #322b7c',
    backgroundColor: Colors.primary,
    borderRadius: '5px',
    width: '100%',
    height: '35px',
    fontFamily: 'Poppins',
    fontSize: 12,
    fontWeight: '600',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: '#ffffff',
    cursor: 'pointer',
    '&:hover': {
      background: Colors.primary,
    },
  },
  textFieldcontainer: {
    width: 530,
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  textFieldLabel: {
    color: '#1c1939',
    fontSize: 12,
    fontFamily: 'Poppins',
  },
  donwnloadBox: {
    width: '282.7px',
    height: 42.7,
    borderRadius: 6.7,
    borderWidth: 1.3,
    borderColor: '#caccd1',
    backgroundColor: '#f2f2f2',
    fontFamily: 'Poppins',
    fontSize: 11.3,
    color: '#1c1939',
    padding: '0px 5px',
  },
  TextFieldRowContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    height: 53.4,
  },
  columnContainer: {width: 530},
  rowContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
  },
  smallTextField: {
    '& .MuiInputBase-input': {
      width: '85px',
      height: '46px',
      borderRadius: 6.7,
      borderWidth: 1.3,
      borderColor: '#caccd1',
      backgroundColor: '#f2f2f2',
      fontFamily: 'Poppins',
      fontSize: 11.3,
      color: '#1c1939',
      padding: '0px 18px',
    },
  },
  areaTextField: {
    '& .MuiInputBase-input': {
      height: 42.7,
      borderRadius: 6.7,
      borderWidth: 1.3,
      borderColor: '#caccd1',
      backgroundColor: '#f2f2f2',
      fontFamily: 'Poppins',
      fontSize: 11.3,
      color: '#1c1939',
      padding: '0px 18px',
    },
  },
  VisitPlanField: {
    '& .MuiInputBase-input': {
      width: 97,
      height: 42.7,
      borderRadius: 6.7,
      borderWidth: 1.3,
      borderColor: '#caccd1',
      backgroundColor: '#f2f2f2',
      fontFamily: 'Poppins',
      fontSize: 11.3,
      color: '#1c1939',
      padding: '0px 18px',
      textAlign: 'center',
    },
  },
  VisitPlanBox: {
    '& .MuiInputBase-input': {
      width: 150,
      height: 42.7,
      borderRadius: 6.7,
      borderWidth: 1.3,
      borderColor: '#caccd1',
      backgroundColor: '#f2f2f2',
      fontFamily: 'Poppins',
      fontSize: 11.3,
      color: '#1c1939',
      padding: '0px 18px',
    },
  },
  loaderTP: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1,
  },
});
