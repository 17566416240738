import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-input': {
      width: 400,
      height: 42.7,
      borderRadius: 6.7,
      borderWidth: 1.3,
      borderColor: '#caccd1',
      backgroundColor: '#f2f2f2',
      fontFamily: 'Poppins',
      fontSize: 11.3,
      color: '#1c1939',
      padding: '0px 18px',
    },
  },
  TextFieldRowContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    height: 53.4,
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    zIndex: 1,
  },
  boxContainer: {
    paddingRight: '80px',
    paddingLeft: '1px',
  },
  flexSpaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
});
