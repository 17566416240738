import {API_PATH, BLOB_FOLDER_NAME} from '@app/common/constants';
import {downloadFile} from '@app/screens/module-specific-utilities/common';
import {Grid} from '@mui/material';
import {FileDownloadBoxTP} from './FileDownloadBoxTP';
import {useStyles} from './styles';
import {CustomTextFieldTP} from '../common-component/CustomTextFieldTP';
import {Box} from '@mui/system';

interface DoctorInfoProps {
  doctorInfoData: any;
}

const DoctorInfoForm: React.FC<DoctorInfoProps> = ({doctorInfoData}) => {
  const styleClass = useStyles();
  const downloadCard = () => {
    const blobFolderName: string = BLOB_FOLDER_NAME?.DOCTOR_TO_UNIVERSE;
    const file: string = doctorInfoData?.supportingDocumentPath;
    const url: string = API_PATH?.doctorApproval?.downloadDocument;
    downloadFile(url, file, blobFolderName);
  };
  return (
    <Grid container>
      <Box className={styleClass.flexSpaceBetween}>
        <Grid container className={styleClass.boxContainer}>
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Name'}
            value={doctorInfoData?.name}
            multiline={true}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Address'}
            value={doctorInfoData?.address}
            multiline={true}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'City'}
            value={doctorInfoData?.cityName}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Qualification '}
            value={doctorInfoData?.qualificationName}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Speciality by Qualification'}
            value={doctorInfoData?.specialisationName}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Area Mapped to Doctor'}
            value={doctorInfoData?.areaMappedtoDoctorName}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Date of Birth'}
            value={
              doctorInfoData?.dateOfBirth
                ? new Date(doctorInfoData.dateOfBirth).toLocaleDateString()
                : ''
            }
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '16px'}}
            label={'Date of Marriage'}
            value={
              doctorInfoData?.dateOfMarriage
                ? new Date(doctorInfoData.dateOfMarriage).toLocaleDateString()
                : ''
            }
          />
        </Grid>
        <Grid container>
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Gender'}
            value={doctorInfoData?.gender}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Pincode'}
            value={doctorInfoData?.pinCode}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Mobile Number'}
            value={doctorInfoData?.mobileNumber}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Whatsapp Number'}
            value={doctorInfoData?.whatsappNumber}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Landmark'}
            value={doctorInfoData?.landmark}
            multiline={true}
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Practicing Since'}
            value={
              doctorInfoData?.practicingSince
                ? new Date(doctorInfoData.practicingSince).toLocaleDateString()
                : ''
            }
          />
          <CustomTextFieldTP
            customStyle={{marginBottom: '20px'}}
            label={'Registration Number'}
            value={doctorInfoData?.registrationNumber}
          />
          <CustomTextFieldTP
            label={'Remark'}
            value={doctorInfoData?.remark}
            multiline={true}
          />
        </Grid>
      </Box>
      <Grid container className={styleClass.TextFieldRowContainer}>
        <FileDownloadBoxTP
          label={'Upload Supporting Document'}
          fileName={doctorInfoData?.supportingDocumentPath}
          handleDownload={downloadCard}
          blobFolderName={BLOB_FOLDER_NAME?.DOCTOR_TO_UNIVERSE}
          url={API_PATH?.doctorApproval?.downloadDocument}
        />
      </Grid>
    </Grid>
  );
};
export default DoctorInfoForm;
