import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: '282.7px',
    Height: 42.7,
    borderRadius: 6.7,
    borderWidth: 1.3,
    borderColor: '#caccd1',
    backgroundColor: '#f2f2f2',
    fontFamily: 'Poppins',
    fontSize: 11.3,
    color: '#1c1939',
    lineHeight: '20px',
    padding: '0px 5px',
  },
  root2: {
    '& .MuiInputBase-input': {
      width: 400,
      height: 42.7,
      borderRadius: 6.7,
      borderWidth: 1.3,
      borderColor: '#caccd1',
      backgroundColor: '#f2f2f2',
      fontFamily: 'Poppins',
      fontSize: 11.3,
      color: '#1c1939',
      padding: '0px 18px',
    },
  },
  rejectModalTextField: {
    width: 400,
    fontFamily: 'Poppins',
    fontSize: 16,
  },
  textFieldcontainer: {
    width: 530,
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  textFieldLabel: {
    color: '#1c1939',
    fontSize: 12,
    fontFamily: 'Poppins',
  },
  TextFieldRowContainer: {
    justifyContent: 'space-between',
    display: 'flex',
    height: 53.4,
  },
  rejectBtn: {
    width: 200,
    height: 42.7,
    borderRadius: 6.7,
    borderWidth: 1.3,
    borderColor: '#322b7c',
    backgroundColor: '#322b7c',
    fontFamily: 'Poppins',
    fontSize: 12.7,
  },
  approveBtn: {
    width: 200,
    height: 42.7,
    borderRadius: 6.7,
    borderWidth: 1.3,
    borderColor: '#322b7c',
    fontFamily: 'Poppins',
    fontSize: 12.7,
  },
});
